import React from "react";
import {Box, Button, Container, Typography} from "@mui/material";
import MiniHeader from "./MiniHeader";
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import StyledButtonv2 from "./StyledButtonv2";
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';

const Section1: React.FC = () => {
    const scrollToSection = () => {
        const element = document.getElementById("howitworks");
        if (element) {
            element.scrollIntoView({behavior: "smooth"});
        }
    };

    return (
        <Box
            sx={{
                position: "relative", // For absolute positioning of the background
                // width: "100%",
                height: "100vh", // Makes the section take the full viewport height
                overflow: "hidden", // Ensures no overflow to other sections
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#ffffff", // Text color
                textAlign: "center", // Center-align text
                backgroundImage: "url('/section1bgx.png')", // Path to your background image
                backgroundSize: "cover", // Ensure the background covers the section
                backgroundPosition: "center top", // Center the image and align it to the top
                backgroundRepeat: "no-repeat",
                top: '-75px'
            }}
        >
            {/* Content */}
            <Container maxWidth="md">
                <MiniHeader><LocalPoliceOutlinedIcon sx={{marginRight: "5px"}}/>Lock Securely</MiniHeader>
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: {xs: "2rem", md: "4rem"},
                        fontWeight: "bold",
                        marginBottom: 2,
                        mt: 4
                    }}
                >
                    YOUR TOKENS, YOUR RULES: LOCK, WAIT, WIN
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: {xs: "1rem", md: "1.25rem"},
                        marginBottom: 4,
                        color: "rgba(255, 255, 255, 0.6)",
                    }}
                >
                    A simple way to HODL smarter and maximize rewards.
                </Typography>
                <StyledButtonv2 to="/#howitworks" onClick={scrollToSection}>
                    <ArrowCircleDownRoundedIcon sx={{marginRight: "6px"}}/>Get started
                </StyledButtonv2>
            </Container>
        </Box>
    );
};

export default Section1;
